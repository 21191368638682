import http from '../http';
import { BasicSuccessResponse, ErrorResponse } from './common';
import { PermissionList } from './permission';

export type OwnerPrivacySetting = {
    id: number;
    system_id: number;
    user_id: number;
    show_email_admins: boolean;
    show_name_admins: boolean;
    show_phone_admins: boolean;
    show_email_users: boolean;
    show_name_users: boolean;
    show_phone_users: boolean;
};

type SetSystemUserRequest = {
    user: {
        name?: string;
        phone?: string;
        email?: string;
        code?: string;
        pgms: number | null;
        areas?: string; // Maybe
        zone_number?: number;
        enable_data?: number | boolean;
        attributes_in_panel: number;
    };
    system_id: number;
    canSeeEvents?: boolean;
    canEditUsers?: boolean;
    canBypassZones?: boolean;
    canAccessAdvSettings?: boolean;
    masterCode: string | null;
    isContactPerson: boolean;
};
type DeleteSystemUserRequest = { system_id: number; user_id: number };
type SetSystemUserResponseErrorWithUser = ErrorResponse & { user: number };
type DeleteSystemUserResponse = ErrorResponse | SetSystemUserResponseErrorWithUser | BasicSuccessResponse;
type SetSystemUserResponse = ErrorResponse | SetSystemUserResponseErrorWithUser | (BasicSuccessResponse & {
    protegus_user_id: number;
    id: number;
    system_permissions_id: number | null;
    personal_permissions: PermissionList | null;
    master: boolean;
    monas: undefined | {
        monas_id: number | 0,
        monas_person_id: number | 0,
        related_person_ids: string | null;
    }
});

const addSystemUser = (req: SetSystemUserRequest) => http.post<SetSystemUserResponse, SetSystemUserRequest>('/v3/api/user', req);
const editSystemUser = (req: SetSystemUserRequest) => http.put<SetSystemUserResponse, SetSystemUserRequest>('/v3/api/user', req);
const deleteSystemUser = (req: DeleteSystemUserRequest) => http.delete<DeleteSystemUserResponse, DeleteSystemUserRequest>('/v3/api/user', req);

export default {
    add: addSystemUser,
    save: editSystemUser,
    delete: deleteSystemUser,
};
