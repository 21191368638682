<div class="companyHeader">
  <div class="companyHeaderLogo {{AppSettings.regionsEnabled ? '' : 'companyHeaderLogoSingle'}}">
    <ng-container *ngIf="us.currentUser?.logo || systems.activeSystem?.logo; else ProtegusLogo">
      <img *ngIf="!systems.activeSystem?.logo" [routerLink]="'/'" [src]="companyService.logoBasePath + us.currentUser.logo" class="logo logo_custom" />
      <img *ngIf="systems.activeSystem?.logo" [routerLink]="'/'" [src]="companyService.logoBasePath + systems.activeSystem.logo" class="logo logo_custom" />
    </ng-container>
    <ng-template #ProtegusLogo>
      <div [routerLink]="'/'" class="logo"></div>
    </ng-template>
    <div class="regions" *ngIf="AppSettings.regionsEnabled">
      <app-region-switcher></app-region-switcher>
    </div>
  </div>
  <div class="buttons">
    <div class="firstSimpleUserItem" *ngIf="(us.currentUser?.permissions.permissions.system.create || us.currentUser?.permissions.permissions.dev_setup_templates.execute) && !us.currentUser?.permissions.permissions.systems.create && !us.currentUser?.permissions.permissions.systems.view">
      <app-company-header-button
        icon="../../../../assets/images/company/UserAddSystem.svg"
        [active]="true"
        [label]="trans('systems.titles.' + (us.currentUser?.permissions.permissions.dev_setup_templates.execute ? 'setupDevice' : 'addSystem'))" [rootCommands]="['new-system', 'add-system']"
        (click)="onAddNewSystemClick()">
      </app-company-header-button>
    </div>
    <app-company-header-button
      *ngIf="!us.currentUser?.permissions.permissions.systems.view && !us.currentUser?.permissions.permissions.unassigned_devices.view"
      icon="../../../../assets/images/company/Home.svg"
      [active]="true"
      [label]="trans('systems.buttons.home')" [rootCommands]="['/d']">
    </app-company-header-button>
    <!-- <app-company-header-button icon="../../../../assets/images/company/CompanyDashButton.svg" label="Dash" [exact]="true" [commands]="[]"></app-company-header-button> -->
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.systems.view || us.currentUser?.permissions.permissions.unassigned_devices.view"
      icon="../../../../assets/images/company/CompanySystemsButton.svg"
      [label]="trans('systems.titles.systems')"
      [commands]="['systems']"
      [active]="true"
    ></app-company-header-button>
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.events.view"
      icon="../../../../assets/images/company/CompanyEventsButton.svg"
      [label]="trans('systems.menu.events')"
      [commands]="['events']"
      [active]="true"
    ></app-company-header-button>
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.users.view"
      icon="../../../../assets/images/company/CompanyUsersButton.svg"
      [label]="trans('systems.menu.users')"
      [commands]="['users']"
      [active]="true"
    ></app-company-header-button>
    <!-- <app-company-header-button icon="../../../../assets/images/company/CompanyTagsButton.svg" label="Tags" [commands]="[]" [exact]="true"></app-company-header-button> -->
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.company_settings.view"
      icon="../../../../assets/images/all_companies.svg"
      [label]="trans('companies.titles.list')"
      [commands]="['companies']"
      [active]="true"
    ></app-company-header-button>
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.roles.view"
      icon="../../../../assets/images/company/userRoles.svg"
      [label]="trans('permissions.titles.list')"
      [commands]="['permissions']"
      [active]="true"
    ></app-company-header-button>
    <app-company-header-button
      *ngIf="us.currentUser?.permissions?.permissions.tags.view && us.currentUser?.user_tags.length === 0"
      icon="../../../../assets/images/tag.svg"
      [label]="trans('tags.titles.list')"
      [commands]="['tags']"
      [active]="true"
    ></app-company-header-button>
    <div class="help">
      <app-company-header-button
        *ngIf="(!us.currentUser?.belongsToCompany || us.currentUser?.belongsToCompany?.can_be_seen) && us.currentUser?.permissions?.permissions.pending_systems.view"
        icon="../../../../assets/images/pending_systems.svg"
        [label]="trans('systems.labels.pendingSystem.listTitle')"
        [commands]="['pending-systems']"
        [active]="true"
      ></app-company-header-button>
      <div *ngIf="pendingSystemService.hasPendingSystems" class="help-dot"><app-dot></app-dot></div>
    </div>
    <app-company-header-button
      *ngIf="urlButtonEnabled"
      icon="../../../../{{urlButtonIcon}}"
      [label]="urlButtonTitle"
      [commands]="'#'"
      (onCustomAction)="urlButtonClicked()"
      [active]="false"
    ></app-company-header-button>

    <div
      *ngIf="hasAnySettingsAvailable"
      (mouseenter)="toggleSettings()"
      (mouseleave)="toggleSettings()"
      class="settings"
    >
      <app-company-header-button
        icon="../../../../assets/images/company/CompanySettingsButton.svg"
        [label]="trans('systems.menu.settings')"
        [commands]="['settings']"
        [active]="false"
        [sectionVisible]="showSettings"
      ></app-company-header-button>
      <ng-container *ngIf="showSettings">
        <app-settings-list (visibilityChange)="showSettings = $event"></app-settings-list>
      </ng-container>
    </div>

    <div class="help">
      <app-company-header-button
        icon="../../../../assets/images/company/UserHelp.svg"
        [label]="trans('systems.menu.help')"
        [rootCommands]="['help']"
        [active]="true"
      ></app-company-header-button>
      <div *ngIf="newVersionAvailable" class="help-dot"><app-dot></app-dot></div>
    </div>
  </div>
  <div class="userSection">
    <div (click)="toggleProfileSettings()" class="welcomeUser">
      <div id="toggleSettings" class="welcomeUserName">
        <p id="toggleSettings">{{trans('general.hello')}}, </p>
        <p id="toggleSettings" class="welcomeUserNameText">{{ us.currentUser?.name ?? trans('users.labels.user') }}</p>
      </div>
      <div *ngIf="us.currentUser?.permissions.role" id="toggleSettings" class="role">{{ us.currentUser?.permissions.name }}</div>
    </div>
    <a (click)="toggleProfileSettings()">
      <div id="toggleSettings" class="userIcon"></div>
    </a>
    <a [routerLink]="['/login']" (click)="login.logout()"><app-button type="filled-secondary">{{trans('systems.buttons.logout')}}</app-button></a>
  </div>
</div>
<ng-container *ngIf="showProfileSettings">
  <app-company-header-settings (showSettingsChange)="onShowSettingsChange($event)"></app-company-header-settings>
</ng-container>
