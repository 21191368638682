import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { BackBehaviourService } from 'src/app/services/back-behaviour.service';
import { SensorService } from 'src/app/api/system/sensor.service';
import { GetSystemStatusNewResponse, SosType, SystemTrouble, transferDevice } from 'src/api/v3/system';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { ErrorResponse } from 'src/api/v3/common';
import { defaultHomeConfiguration } from 'src/app/api/user.service';
import { HomeConfigurationElementType } from 'src/app/models/home-configuration';
import { Subscription } from 'rxjs';
import { LocatorService } from 'src/app/services/locator.service';
import { HeaderService } from 'src/app/services/header.service';
import requests from 'src/api/v3/requests';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { SignalLevelV2Component } from 'src/app/components/signal-level-v2/signal-level-v2.component';
import { AssistingInstallerComponent } from 'src/app/system/assisting-installer/assisting-installer.component';
import { ButtonComponent } from 'src/app/ui/button/button.component';
import { LoaderRoundComponent } from 'src/app/components/loader-round/loader-round.component';
import { SosButtonComponent } from './sos-button/sos-button.component';
import { HomeEventsComponent } from './home-events/home-events.component';
import { VirtualAreaViewComponent } from 'src/app/system/virtual-area-view/virtual-area-view.component';
import { HomeAreasComponent } from './home-areas/home-areas.component';
import { HomeOutputsComponent } from './home-outputs/home-outputs.component';
import { HomeSensorsComponent } from './home-sensors/home-sensors.component';
import { HomeCamerasComponent } from './home-cameras/home-cameras.component';
import { HomeThermostatsComponent } from './home-thermostats/home-thermostats.component';
import { HomeDeviceStatusComponent } from './home-device-status/home-device-status.component';
import { MeResponse } from 'src/api/v3/user';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { MessageBoxButtons } from 'src/app/popups/messagebox/messagebox-config';
import { AreaState } from 'src/api/v3/system.area';
import { TAreaData } from 'src/app/models/area-data';
import { TZoneData } from 'src/app/models/zone-data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, InlineSVGModule, SignalLevelV2Component, AssistingInstallerComponent, ButtonComponent, LoaderRoundComponent, SosButtonComponent, HomeEventsComponent, VirtualAreaViewComponent, HomeAreasComponent, HomeOutputsComponent, HomeSensorsComponent, HomeCamerasComponent, HomeThermostatsComponent, HomeDeviceStatusComponent, MessageboxComponent],
})
export class HomeComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly MessageBoxButtons = MessageBoxButtons;
  private systemChangeSubscription: Subscription;
  private refreshSubscription: Subscription;
  private silentRefreshSubscription: Subscription;
  private systemTroubleListener: Subscription;
  public transferIsInProgress = false;
  public checkingForOnlineSystem = false;
  public shouldShowNewSystemWarning = false;
  public shouldShowTroubleWarning = false;
  public showTroubleWarningMessagebox = false;
  private newSystemWarningTimeout = null;
  public readonly PermissionRole = PermissionRole;
  private get headerService() { return LocatorService.injector.get(HeaderService); }
  private currentSystem: number | null;
  public troubles: SystemTrouble[] = [];
  public openZones: {area: TAreaData, zone: TZoneData }[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private ws: WebSocketService, // tik tam, kad butu uzkrauta
    private router: Router,
    private ns: NewSystemService,
    private senS: SensorService,
    private bh: BackBehaviourService,
    private msg: MessageboxService,
    private r: ActivatedRoute,
  ) {
    super(cdRef, { shouldScrollTop: false });
    this.tag = 'Home';
    this.log('+');
    if ( this.systems.activeSystem !== null && this.r.snapshot.data.bare ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    if ( this.systems.activeSystem ) {
      this.systems.setCurrentSystem(this.systems.activeSystem);
    }
    this.background.setUserSelected();

    this.systemChangeSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      this.handleSystemChange();
      this.handleHeaderView();
      this.determineIfNewSystemWarningShouldBeShown();
      this.renderTroublesText();
      this.showTroubleWarningMessagebox = false;
    });
    this.handleHeaderView();
    this.refreshSubscription = this.refresher.onRefresh.subscribe(() => {
      this.doRefresh();
    });
    this.systemTroubleListener = this.ws.onSystemTroublesReceived.subscribe(data => {
      if ( !this.systems.activeSystem ) { return; }
      if ( this.systems.activeSystem.id !== data.systemId ) { return; }
      this.renderTroublesText();
    });
    this.silentRefreshSubscription = this.refresher.onSilentRefreshComplete.subscribe(() => {
      this.getSystemStatus();
    })
    localStorage.setItem('ref', 'home');
    this.refresher.enableRefresher();
    this.determineIfNewSystemWarningShouldBeShown();
    this.handleSystemChange();
    this.renderTroublesText();
  }

  private handleHeaderView() {
    if (this.systems.activeSystem !== null) {
      const canRequestAssistance = this.systems.currentUserPermissions?.permissions.sys_assistance.execute && (
                                    this.systems.activeSystem.installerId !== 0
                                    || this.us.currentUser.permissions.permissions.users.view
                                  ) &&
                                  this.us.currentUser.permissions.role !== PermissionRole.Installer;
      this.headerBar.showHeader({
        showDotMenu: this.us.currentUser.permissions?.role === PermissionRole.GeneralUser,
        showHamburger: true,
        showHome: this.us.currentUser.permissions?.role !== PermissionRole.GeneralUser,
        showInstallerAssist: canRequestAssistance,
        brightMode: true,
      });
      this.headerBar.onHomeButtonClicked = () => {
        this.systems.setCurrentSystem(null);
        this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
      };
      if ( canRequestAssistance ) {
        this.headerBar.onActionButtonClicked = () => {
          this.router.navigate(['request-assistance'], { relativeTo: this.r });
        };
      }
    } else {
      this.headerBar.showHeader({
        showHamburger: true,
        brightMode: true,
      });
    }
    if ( this.us.currentUser.permissions?.role === PermissionRole.GeneralUser ) {
      if ( this.platform.isDesktopView() ) {
        this.headerBar.setDotMenuItems([{
          name: this.trans('systems.menu.editHomeScreen'),
          action: () => { this.router.navigate(['home-configuration'], { relativeTo: this.r }); },
        }]);
      } else {
        this.headerBar.setDotMenuItems([{
          name: this.trans('systems.menu.editHomeScreen'),
          action: () => { this.router.navigate(['home-configuration'], { relativeTo: this.r }); },
        }, {
          name: this.trans('systems.menu.systemConfiguration'),
          action: () => { this.router.navigate(['settings'], { relativeTo: this.r }); },
        }]);
      }
    }
    

    if (this.platform.isAndroid()) {
      if (this.us.currentUser.permissions?.permissions.systems.view) {
        this.headerService.setBackURL('*');
        this.platform.androidHandler().setBackButtonType('normal');
      } else {
        this.platform.androidHandler().setBackButtonType('close');
      }
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.systemChangeSubscription?.unsubscribe();
    this.refreshSubscription?.unsubscribe();
    this.silentRefreshSubscription?.unsubscribe();
    this.systemTroubleListener?.unsubscribe();
  }

  public newSystemClick() {
    this.ns.setDefaults();
    this.router.navigate(['new-system', 'add-system']);
  }

  public hasSystem(): boolean {
    return this.systems.hasSystems() && this.systems.activeSystem !== null;
  }

  public systemNetState(): string {
    return this.systems.activeSystem.online ? this.trans('systems.statuses.online') : this.trans('systems.statuses.offline');
  }

  private handleSystemChange() {
    if (this.systems.activeSystem !== null) {
      if (this.us.currentUser.permissions?.role === PermissionRole.GeneralUser) {
        this.headerBar.doShowDotMenu();
      }
      this.background.setUserSelected();
      if ( this.currentSystem !== this.systems.activeSystem.id ) {
        this.currentSystem = this.systems.activeSystem.id;
        if ( this.systems.activeSystemHelper.can.requestSos() && this.systems.activeSystem.sos_type === SosType.WithGPS ) {
          this.handleLocationPermission();
        }
        this.getSystemStatus();
      }
      this.renderTroublesText();
    }
  }

  public getPositionForType(type: HomeConfigurationElementType): string {
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.system_id === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined || systemHomeConfig.elementOrder.find((t) => t.type === type) === undefined) {
      return defaultHomeConfiguration.elementOrder.find((t) => t.type === type).position.toString();
    }
    return systemHomeConfig.elementOrder.find((t) => t.type === type).position.toString();
  }

  public shouldShowType(type: HomeConfigurationElementType) {
    if ( type === 'areas' && !this.systems.currentUserPermissions?.permissions.sys_areas.view ) { return false; }
    if ( type === 'cameras' && !this.systems.currentUserPermissions?.permissions.sys_cameras.view ) { return false; }
    if ( type === 'events' && !this.systems.currentUserPermissions?.permissions.sys_events.view ) { return false; }
    if ( type === 'outputs' && !this.systems.currentUserPermissions?.permissions.sys_outputs.view ) { return false; }
    if ( type === 'sensors' && !this.systems.currentUserPermissions?.permissions.sys_sensors.view ) { return false; }
    if ( type === 'thermostats' && !this.systems.currentUserPermissions?.permissions.sys_thermostats.view ) { return false; }
    if ( type === 'device-status' && !this.systems.currentUserPermissions?.permissions.sys_information.view ) { return false; }
    if ( this.us.currentUser.permissions.permissions.systems.view || this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
      return true;
    }
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.system_id === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined) {
      return true;
    }
    const found = systemHomeConfig.elementOrder.find((t) => t.type === type);
    return found === undefined || found.visible;
  }

  public async doRefresh() {
    this.log('Refresh');
    this.senS.watcherStatus.isWorking = true;
    try {
      const result:MeResponse = await this.requestService.runSequentially(requests.user.me({ systemId: this.systems.activeSystem?.id ?? 0, areaId: 0, getSystemStatus: true }));
      if (result.success) {
        if(result.lastSystemStatus && result.lastSystemStatus.zones) {
          this.gotZoneStatuses(result.lastSystemStatus.zones, this.systems.activeSystem.id);
        }
        if (!this.api.hasToken()) {
          // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
          this.senS.watcherStatus.isWorking = false;
          return;
        }
        this.us.setCurrentUserFromRaw(result);
        if ( result.tags ) {
          result.tags.forEach(t => {
            this.tagService.ingestTag(t);
          });
        }
        this.us.change();
        this.api.setToken(result.token);
        if(this.us.currentUser && this.us.currentUser.language !== localStorage.getItem('lang')) {
          this.api.post('/translations', {
            language: this.us.currentUser.language,
            version: '-1',
          }, false).subscribe((data) => {
            if (data.success) {
              this.ls.selectedLanguage.value = data.code;
              this.ls.applyTranslations(data.translations, data.version, data.code);
            }
          });
        }
        if (result.lastSystem !== undefined) {
          this.systems.setCurrentSystemFromRaw(result.lastSystem);
          if (this.systems.activeSystem?.sensors.length > 0) {
            this.senS.startWatcher(true);
          }
          if ( this.systems.activeSystem && this.systems.activeSystem.imei !== '' && !this.systems.activeSystem.online && !this.checkingForOnlineSystem && !this.transferIsInProgress ) {
            this.checkingForOnlineSystem = true;
            const activeSystem = this.systems.activeSystem;
            requests.system.isOnline({
              imei: this.systems.activeSystem.imei,
              checkAll: true,
              mpass: this.systems.activeSystem.mpass,
            }).toPromise().then((onlineRes) => {
              this.checkingForOnlineSystem = false;
              let supported = false;
              if (activeSystem.supported_commands.indexOf('.18.') !== -1 || activeSystem.supported_commands.endsWith('.18')) {
                supported = true;
              } else if (activeSystem.supported_commands.indexOf('.22.') !== -1 || activeSystem.supported_commands.endsWith('.22')) {
                supported = true;
              }
              if ( onlineRes.success && onlineRes.online && !onlineRes.own_region && supported ) {
                this.msg.open({
                  buttons: this.msg.buttons.YesNoCancel,
                  headerText: this.trans('systems.titles.transferDevice'),
                  messageContent: this.trans('systems.labels.deviceInDifferentRegion'),
                }, (p) => {
                  p.yesClicked.subscribe(() => {
                    this.doMoveDevice({
                      id: activeSystem.id,
                      imei: activeSystem.imei,
                      mpass: activeSystem.mpass,
                      srv: onlineRes.srv,
                    });
                  });
                });
              }
            });
          }
        }
      } else {
        this.toaster.postError((result as ErrorResponse).error);
      }
    } catch (e) {
      this.l.log('Nepavyko atnaujinti vartotojo duomenu', this.tag);
    }
    this.senS.watcherStatus.isWorking = false;
  }

  private doMoveDevice(opts: any) {
    this.transferIsInProgress = true;
    let onlineWaitSubscriber = null;
    const timeOutTimer = setTimeout(() => {
      onlineWaitSubscriber?.unsubscribe();
      onlineWaitSubscriber = null;
      this.transferIsInProgress = false;
      this.msg.open({
        buttons: this.msg.buttons.Close,
        headerText: this.trans('general.titleError'),
        iconType: this.msg.iconType.Error,
        messageContent: this.trans('systems.errors.failedToTransferDevice'),
      });
    }, 60000);
    onlineWaitSubscriber = this.ws.onSystemStatusChange.subscribe((newStatus) => {
      if ( newStatus.system_id !== opts.id ) { return; }
      if ( newStatus.system_status === 'online' ) {
        clearTimeout(timeOutTimer);
        this.transferIsInProgress = false;
        this.msg.open({
          buttons: this.msg.buttons.Close,
          messageContent: this.trans('general.titleSuccess'),
          iconType: this.msg.iconType.Success,
        });
      }
      onlineWaitSubscriber?.unsubscribe();
      onlineWaitSubscriber = null;
    });
    transferDevice({
      systemUid: opts.imei,
      mpass: opts.mpass,
      srv: opts.srv,
    }).subscribe(
      res => {
        if ( !res.success ) {
          clearTimeout(timeOutTimer);
          onlineWaitSubscriber?.unsubscribe();
          onlineWaitSubscriber = null;
          this.transferIsInProgress = false;
          this.msg.open({
            buttons: this.msg.buttons.Close,
            headerText: this.trans('general.titleError'),
            iconType: this.msg.iconType.Error,
            messageContent: (res as ErrorResponse).error,
          });
        }
      },
      error => {
        clearTimeout(timeOutTimer);
        onlineWaitSubscriber?.unsubscribe();
        onlineWaitSubscriber = null;
        this.transferIsInProgress = false;
      }
    );
  }

  private determineIfNewSystemWarningShouldBeShown() {
    if ( !this.systems.activeSystem ) {
      this.shouldShowNewSystemWarning = false;
      clearTimeout(this.newSystemWarningTimeout);
      return;
    }
    const secondsAfterCreation = ((new Date().getTime()) - this.systems.activeSystem.created_at * 1000) / 1000;
    if ( secondsAfterCreation < 80 ) {
      clearTimeout(this.newSystemWarningTimeout);
      this.shouldShowNewSystemWarning = true;
      this.newSystemWarningTimeout = setTimeout(() => {
        this.shouldShowNewSystemWarning = false;
      }, (80 - secondsAfterCreation) * 1000);
    } else {
      this.shouldShowNewSystemWarning = false;
      clearTimeout(this.newSystemWarningTimeout);
    }
  }

  public goToTransfer() {
    this.router.navigate(['settings', 'transfer-type'], { relativeTo: this.r });
  }

  private handleLocationPermission() {
    const hasLocationPermissionResponseHandler = val => {
      if ( !val ) {
        if ( this.platform.isAndroid() ) {
          try {
            this.platform.androidHandler().canAskForLocationPermission();
          } catch (e) { }
        } else if ( this.platform.isApple() ) {
          this.platform.appleHandler().canAskForLocationPermission.postMessage('');
        }
      }
    };
    const canAskForLocationPermissionResponseHandler = val => {
      if ( val ) {
        this.msg.open({
          buttons: this.msg.buttons.Ok,
          alignCenter: true,
          headerText: this.trans('systems.titles.permissionNeeded'),
          messageContent: this.trans('systems.labels.locationPermissionInfoForSos'),
        }, dlg => {
          const okSub = dlg.okClicked.subscribe(() => {
            okSub.unsubscribe();
            if ( this.platform.isAndroid() ) {
              try {
                this.platform.androidHandler().getLocationPermission(); // callback ateis async, kitoj vietoj.
              } catch (e) { }
            } else if ( this.platform.isApple() ) {
              this.platform.appleHandler().getLocationPermission.postMessage('');
            }
          });
        });
      }
    }

    if ( window.phoneStateJsi ) {
      window.phoneStateJsi.hasLocationPermissionResponse = hasLocationPermissionResponseHandler;
      window.phoneStateJsi.canAskForLocationPermissionResponse = canAskForLocationPermissionResponseHandler;
    } else {
      window.phoneStateJsi = {
        hasLocationPermissionResponse: hasLocationPermissionResponseHandler,
        canAskForLocationPermissionResponse: canAskForLocationPermissionResponseHandler,
      };
    }

    if ( this.platform.isAndroid() ) {
      try {
        this.platform.androidHandler().hasLocationPermission();
      } catch (e) { }
    } else if ( this.platform.isApple() ) {
      this.platform.appleHandler().hasLocationPermission.postMessage('');
    }
  }

  private getSystemStatus() {
    if ( !this.systems.activeSystem || this.systems.activeSystem.imei === '' ) { return; }
    const sensorRefresh = localStorage.getItem('sensor_auto_refresh_' + this.systems.activeSystem.id);
    if ( !this.systems.activeSystemHelper.can.see.sensors() || !this.shouldShowType('sensors') || this.systems.activeSystem.sensors.length === 0 || sensorRefresh === '0' ) {
      this.requestService.runSequentially(requests.system.getStatus({system_id: this.systems.activeSystem.id})).then((response: GetSystemStatusNewResponse) => {
        // iš statuso kol kas apdorojamos tik zonų būsenas
        if(response.success && response.data.zones) {
          this.gotZoneStatuses(response.data.zones, this.systems.activeSystem.id);
        }
      });
    }
  }

  public onTroubleWarningClick() {
    this.showTroubleWarningMessagebox = true;
  }

  private renderTroublesText() {
    this.troubles = [];
    this.openZones = [];

    if(!this.systems.activeSystem || !this.systems.activeSystem.troubles) {
      this.shouldShowTroubleWarning = false;
      this.showTroubleWarningMessagebox = false;
      return;
    }

    const troubles = this.systems.activeSystem.troubles.filter(trb => trb.enabled && !trb.ok);
    if(troubles.length === 0) {
      this.shouldShowTroubleWarning = false;
      this.showTroubleWarningMessagebox = false;
      return;
    }
    this.troubles = troubles;

    const disarmedNotReadyAreas = this.systems.activeSystem.areas.filter(a => a.status === AreaState.DisarmedNotReady);
    disarmedNotReadyAreas.forEach(area => {
      const zones = this.systems.activeSystem.zones.filter(z => z.areas.includes(area.queue_no) && z.alarmed && !z.bypassed);
      zones.forEach(zone => {
        this.openZones.push({ 'area': area, 'zone': zone });
      });
    });

    this.shouldShowTroubleWarning = true;
  }

  private gotZoneStatuses(zones: any, systemId: number) {
    this.log('Grizo zonu busenos: ', zones);
    const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
    if (system === undefined) {
      return;
    }

    for (const iZone of Object.keys(zones)) {
      const zone = system.zones.find((z) => z.queue_no === parseInt(iZone, 10));
      if (zone === undefined) {
        continue;
      }
      zone.bypassed = zones[iZone].bypass;
      zone.alarmed = zones[iZone].alarm;
      zone.failed = zones[iZone].failure;
    }
  }
}
