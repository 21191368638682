<h4 *ngIf="desktopView"><app-back-button [defaultBehaviour]="false" (buttonPressed)="backButtonPressed()" [style.display]="'inline-block'"></app-back-button>{{ trans('systems.menu.systemInformation') }}</h4>
<div class="white_round padding8">
    <app-text-edit [isReadOnly]="true" [value]="device?.imei">{{trans('systems.labels.imei')}} <span class="copy-imei" (click)="copyImei(device?.imei)"><svg width="11.935" height="11.935" viewBox="0 0 11.935 11.935">
        <g transform="translate(0.5 0.5)">
        <path d="M14.594,13.5h4.921a1.094,1.094,0,0,1,1.094,1.094v4.921a1.094,1.094,0,0,1-1.094,1.094H14.594A1.094,1.094,0,0,1,13.5,19.514V14.594A1.094,1.094,0,0,1,14.594,13.5Z" transform="translate(-9.673 -9.673)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path d="M4.64,10.108H4.094A1.094,1.094,0,0,1,3,9.014V4.094A1.094,1.094,0,0,1,4.094,3H9.014a1.094,1.094,0,0,1,1.094,1.094V4.64" transform="translate(-3 -3)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
    </svg></span></app-text-edit>
    <app-tag-input *ngIf="canSeeTags && device" #tags [label]="trans('systems.labels.systemTags')" [initialTags]="device.tags" [isReadOnly]="!canEditTags || !canEdit" [canCreateNewTags]="canCreateTags" (tagsChanged)="changed()"></app-tag-input>
    <div *ngIf="canSeeCompany && device">
        <app-dropdown [items]="companyService.listStorage" [(value)]="device.company_id" (valueChange)="rebuildCompanyName(); changed()" [disabled]="!canEdit || saving" label="{{trans('systems.labels.systemBelongsToCompany')}}"></app-dropdown>
    </div>
</div>
<div class="separator32"></div>
<div class="white_round" *ngIf='us.currentUser.permissions.permissions.sys_advanced_settings.view'>
    <app-button-single-line-arrow (click)="openAdvancedSettings()">{{trans('systems.menu.advancedSettings')}}</app-button-single-line-arrow>
</div>
<div class="separator32"></div>
<div class="white_round" *ngIf="us.currentUser.permissions.permissions.unassigned_devices.delete">
    <app-button-single-line-arrow (click)="deleteDevice()">{{trans('systems.buttons.deleteRegistration')}}</app-button-single-line-arrow>
</div>
<div class="separator32"></div>
<div class="white_round" *ngIf="us.currentUser.permissions.permissions.system.create || us.currentUser.permissions.permissions.systems.create">
    <app-button-single-line-arrow (click)="setupSystem()">{{trans('systems.buttons.setupSystem')}}</app-button-single-line-arrow>
</div>
<div class="separator32"></div>