import { AppSettings } from 'src/app-settings';
import { ApiRequestService } from './api-request.service';
import { LocatorService } from './locator.service';
import { SystemsService } from './systems.service';
import { HttpRequestError } from '../api/request.service';

export const sendError = (error: { message?: string; stack?: string } | HttpRequestError) => {
  const api: ApiRequestService = LocatorService.injector.get(ApiRequestService);
  const systems: SystemsService = LocatorService.injector.get(SystemsService);
  const ver = `${AppSettings.appVersionMajor}.${AppSettings.appVersionMinor}.${AppSettings.appVersionBuild}`;
  const messageToSend = error.message && error.message !== '' ? error.message : (error instanceof HttpRequestError ? error.cause.message : 'Neaiški klaida tada, nes nėra nei message, nei cause.');
  const stackToSend = error.stack ?? (error instanceof HttpRequestError ? error.cause.stack : 'Neturim stack');
  api.post('/error', { msg: messageToSend, stack: stackToSend, activeSystem: systems.activeSystem ? systems.activeSystem.id : 0, version: ver, url: error instanceof HttpRequestError ? error.url : undefined }, true).subscribe(
    (result) => {
      // ok
    },
    () => {
      console.warn('[ERROR] Deja, nepavyko nusiųsti klaidos pranešimo.');
    }
  );
};
