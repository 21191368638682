import Stripe from 'stripe';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';

export type CreateStripeSettingsRequest = {
    region_base_url: string;
    test_mode: boolean;
    secret_key_live?: string;
    secret_key_test?: string;
    create_recommended_defaults?: boolean;
};

export type UpdateStripeSettingsRequest = {
    enabled?: boolean;
    test_mode?: boolean;
    secret_key_live?: string;
    secret_key_test?: string;
    region_base_url?: string;
    create_recommended_defaults?: boolean;
};

export type StripeSettingsResponseData = {
    id: number,
    company_id: number,
    enabled: boolean,
    test_mode: boolean,
    charges_enabled: boolean,
    payouts_enabled: boolean,
    webhook_enabled: boolean,
    hasTestModeSet: boolean,
    hasLiveModeSet: boolean,
    account_id: string,
    default_currency: string,
};

export type UpdateSubscriptionRequest =         { id: string; params: Stripe.SubscriptionUpdateParams; use_parent_key: boolean; delete_discount: boolean; };
export type CreateStripeCustomerRequest =       { company_id: number; test_mode: boolean; params: Stripe.CustomerCreateParams };
export type CreateStripeQuoteRequest =          { params: Stripe.QuoteCreateParams };
export type CreateCustomerPortalRequest =       { customer: string; return_url: string; };
export type GetQuoteRequest =                   { id: string; use_parent_key?: boolean; };
export type GetInvoiceRequest =                 { id: string; use_parent_key?: boolean; };
export type GetQuoteLineItemsRequest =          { id: string; use_parent_key?: boolean; };
export type GetUpcomingInvoiceLinesRequest =    { id: string; use_parent_key?: boolean; };
export type GetSubscriptionRequest =            { id: string; use_parent_key?: boolean; };
export type GetStripeAccountRequest =           { test_mode: boolean; secret_key?: string; };
export type GetStripeWebhookEndpointRequest =   { test_mode: boolean; };
export type GetParentParamStatusRequest =       { param: 'test_mode' | 'enabled'; };
export type FinalizeStripeQuoteRequest =        { id: string; };
export type FinalizeStripeInvoiceRequest =      { id: string; };
export type CancelStripeQuoteRequest =          { id: string; use_parent_key?: boolean; };
export type AcceptStripeQuoteRequest =          { id: string; finalize_invoice: boolean; };

export type GetStripePricesResponse =               ErrorResponse | (BasicSuccessResponse & { data: Stripe.Price[] });
export type GetStripeProductsResponse =             ErrorResponse | (BasicSuccessResponse & { data: Stripe.Product[] });
export type GetParentStripeProductNamesResponse =   ErrorResponse | (BasicSuccessResponse & { data: { id: string, name: string }[] });
export type GetStripeCouponsResponse =              ErrorResponse | (BasicSuccessResponse & { data: Stripe.Coupon[] });
export type GetStripeTaxRegistrationsResponse =     ErrorResponse | (BasicSuccessResponse & { data: Stripe.Tax.Registration[] });
export type GetQuotesResponse =                     ErrorResponse | (BasicSuccessResponse & { data: Stripe.Quote[] });
export type GetStripeCurrencySettingsResponse =     ErrorResponse | (BasicSuccessResponse & { data: { default_currency?: string, default_currency_parent?: string } });
export type GetParentTestModeStatusResponse =       ErrorResponse | (BasicSuccessResponse & { data: boolean });
export type GetParentParamStatusResponse =          ErrorResponse | (BasicSuccessResponse & { data: boolean });
export type GetSubscriptionsResponse =              ErrorResponse | (BasicSuccessResponse & { data: Stripe.Subscription[] });
export type CreateStripeSettingsResponse =          ErrorResponse | (BasicSuccessResponse & { data: StripeSettingsResponseData });
export type UpdateStripeSettingsResponse =          ErrorResponse | (BasicSuccessResponse & { data: StripeSettingsResponseData });
export type GetStripeSettingsResponse =             ErrorResponse | (BasicSuccessResponse & { data: StripeSettingsResponseData });
export type GetQuoteResponse =                      ErrorResponse | (BasicSuccessResponse & { data: Stripe.Quote });
export type GetInvoiceResponse =                    ErrorResponse | (BasicSuccessResponse & { data: Stripe.Invoice });
export type GetQuoteLineItemsResponse =             ErrorResponse | (BasicSuccessResponse & { data: Stripe.LineItem[] });
export type GetUpcomingInvoiceLinesResponse =       ErrorResponse | (BasicSuccessResponse & { data: Stripe.InvoiceLineItem[] });
export type GetSubscriptionResponse =               ErrorResponse | (BasicSuccessResponse & { data: Stripe.Subscription });
export type UpdateSubscriptionResponse =            ErrorResponse | (BasicSuccessResponse & { data: Stripe.Subscription });
export type GetStripeAccountResponse =              ErrorResponse | (BasicSuccessResponse & { data: Stripe.Account });
export type GetStripeWebhookEndpointResponse =      ErrorResponse | (BasicSuccessResponse & { data: { webhook: Stripe.WebhookEndpoint, events?: Stripe.Event[] } });
export type CreateStripeCustomerResponse =          ErrorResponse | (BasicSuccessResponse & { data: Stripe.Customer });
export type CreateStripeQuoteResponse =             ErrorResponse | (BasicSuccessResponse & { data: Stripe.Quote });
export type CreateCustomerPortalResponse =          ErrorResponse | (BasicSuccessResponse & { data: Stripe.BillingPortal.Session });
export type FinalizeStripeQuoteResponse =           ErrorResponse | (BasicSuccessResponse & { data: Stripe.Quote });
export type FinalizeStripeInvoiceResponse =         ErrorResponse | (BasicSuccessResponse & { data: Stripe.Invoice });
export type CancelStripeQuoteResponse =             ErrorResponse | (BasicSuccessResponse & { data: Stripe.Quote });
export type AcceptStripeQuoteResponse =             ErrorResponse | (BasicSuccessResponse & { data: { quote: Stripe.Quote, invoice: Stripe.Invoice } });

const getStripeAccount =            (req: GetStripeAccountRequest) =>           http.get<GetStripeAccountResponse, URLSearchParams>('/v3/api/payments/get-account', objectToParams(req));
const getStripeWebhookEndpoint =    (req: GetStripeWebhookEndpointRequest) =>   http.get<GetStripeWebhookEndpointResponse, URLSearchParams>('/v3/api/payments/get-webhook', objectToParams(req));
const getQuote =                    (req: GetQuoteRequest) =>                   http.get<GetQuoteResponse, URLSearchParams>('/v3/api/payments/get-quote', objectToParams(req));
const getInvoice =                  (req: GetInvoiceRequest) =>                 http.get<GetInvoiceResponse, URLSearchParams>('/v3/api/payments/get-invoice', objectToParams(req));
const getQuoteListLineItems =       (req: GetQuoteLineItemsRequest) =>          http.get<GetQuoteLineItemsResponse, URLSearchParams>('/v3/api/payments/get-quote-line-items', objectToParams(req));
const getUpcomingInvoiceLines =     (req: GetUpcomingInvoiceLinesRequest) =>    http.get<GetUpcomingInvoiceLinesResponse, URLSearchParams>('/v3/api/payments/get-upcoming-invoice-items', objectToParams(req));
const getSubscription =             (req: GetSubscriptionRequest) =>            http.get<GetSubscriptionResponse, URLSearchParams>('/v3/api/payments/get-subscription', objectToParams(req));
const getParentParamStatus  =       (req: GetParentParamStatusRequest) =>       http.get<GetParentParamStatusResponse, URLSearchParams>('/v3/api/payments/get-parent-param-status', objectToParams(req));
const createQuote =                 (req: CreateStripeQuoteRequest) =>          http.post<CreateStripeQuoteResponse, CreateStripeQuoteRequest>('/v3/api/payments/create-quote', req);
const acceptQuote =                 (req: AcceptStripeQuoteRequest) =>          http.post<AcceptStripeQuoteResponse, AcceptStripeQuoteRequest>('/v3/api/payments/accept-quote', req);
const cancelQuote =                 (req: CancelStripeQuoteRequest) =>          http.post<CancelStripeQuoteResponse, CancelStripeQuoteRequest>('/v3/api/payments/cancel-quote', req);
const createCustomer =              (req: CreateStripeCustomerRequest) =>       http.post<CreateStripeCustomerResponse, CreateStripeCustomerRequest>('/v3/api/payments/create-stripe-customer', req);
const updateSubscription =          (req: UpdateSubscriptionRequest) =>         http.post<UpdateSubscriptionResponse, UpdateSubscriptionRequest>('/v3/api/payments/update-subscription', req);
const createStripeSettings =        (req: CreateStripeSettingsRequest) =>       http.post<CreateStripeSettingsResponse, CreateStripeSettingsRequest>('/v3/api/payments/create-settings', req);
const updateStripeSettings =        (req: UpdateStripeSettingsRequest) =>       http.post<UpdateStripeSettingsResponse, UpdateStripeSettingsRequest>('/v3/api/payments/update-settings', req);
const createCustomerPortal =        (req: CreateCustomerPortalRequest) =>       http.post<CreateCustomerPortalResponse, CreateCustomerPortalRequest>('/v3/api/payments/create-stripe-customer-portal', req);

const getSubscriptions =            () => http.get<GetSubscriptionsResponse>('/v3/api/payments/get-subscriptions', undefined);
const getProducts =                 () => http.get<GetStripeProductsResponse>('/v3/api/payments/get-products', undefined);
const getParentProductNames =       () => http.get<GetStripeProductsResponse>('/v3/api/payments/get-parent-product-names', undefined);
const getCoupons =                  () => http.get<GetStripeCouponsResponse>('/v3/api/payments/get-coupons', undefined);
const getTaxRegistrations =         () => http.get<GetStripeTaxRegistrationsResponse>('/v3/api/payments/get-tax-registrations', undefined);
const getPrices =                   () => http.get<GetStripePricesResponse>('/v3/api/payments/get-prices', undefined);
const getQuotes =                   () => http.get<GetQuotesResponse>('/v3/api/payments/get-quotes', undefined);
const getStripeCurrencySettings =   () => http.get<GetStripeCurrencySettingsResponse>('/v3/api/payments/get-stripe-currency-settings', undefined);

export default {
    getSubscription,
    getSubscriptions,
    updateSubscription,
    createCustomer,
    getProducts,
    getCoupons,
    getTaxRegistrations,
    getPrices,
    getQuote,
    getQuotes,
    createQuote,
    getQuoteListLineItems,
    acceptQuote,
    cancelQuote,
    getInvoice,
    getUpcomingInvoiceLines,
    createCustomerPortal,
    createStripeSettings,
    updateStripeSettings,
    getStripeAccount,
    getStripeWebhookEndpoint,
    getParentProductNames,
    getParentParamStatus,
    getStripeCurrencySettings,
};
