<div
  [ngClass]="[size && size === 'small' ? 'formGroupSm' : 'formGroup', inputType === 'checkbox' ? (ngType === 'switch' ? 'hasSwitch' : 'hasCheckbox') : '', hasError ? 'hasError' : '']"
>
  <label *ngIf="label"
    [ngClass]="[
      'label',
      isEmpty ? 'empty' : '',
      inputType === 'textarea' ? size && size === 'small' ? 'labelTextarea-small' : 'labelTextarea' : '',
      inputType === 'textarea' && !platform.isDesktopView() ? 'labelTextarea-mobile' : '',
      inputType === 'checkbox' ? (ngType === 'switch' ? 'labelSwitch' : 'labelCheckbox') : '',
    ]"
    [for]="formId"
    >{{ label }}</label
  >
  <div *ngIf="inputType === 'textarea'; else input" class="textarea-container" [ngStyle]="{ 'height': rows !== undefined ? (rows + 3) + 'em' : height + 'px' }">
    <div *ngIf="platform.isDesktopView()" [ngClass]="[size === 'small' ? 'textarea-numbers' : 'textarea-numbers-base']">
      <div *ngFor="let line of lineNumbers" class="number" [innerHTML]="line"></div>
    </div>
    <textarea
      [id]="formId"
      [name]="formId"
      [value]="inputProxy"
      (input)="inputProxy = $event.target.value"
      [disabled]="disabled"
      (focus)="Focus.emit($event)"
      (blur)="Blur.emit($event)"
      (input)="updateLineNumbers()"
      (scroll)="syncScroll()"
      [ngClass]="[size && size === 'small' ? 'textarea' : 'textarea textarea-base']"
    ></textarea>
    <div class="textarea-mirror" #textareaMirror>{{ inputProxy }}</div>
  </div>
  <ng-template #input>
    <input
      #inputElement
      [id]="formId"
      [name]="formId"
      [(ngModel)]="inputProxy"
      [ngClass]="[size && size === 'small' ? 'fontSizeSm' : 'fontSize', 'input', inputType === 'checkbox' ? (ngType === 'switch' ? 'switch' : 'checkbox') : '', inputType === 'email' ? 'lowerCase' : '', ngType === 'hex' ? 'upperCase' : '']"
      [type]="inputType"
      [max]="max"
      [min]="min"
      [step]="step"
      [required]="required"
      [disabled]="disabled"
      [checked]="inputProxy"
      [autocomplete]="autocomplete"
      (change)="onChange($event)"
      (focus)="Focus.emit($event)"
      (blur)="Blur.emit($event)"
      [maxlength]="maxLength"
      (input)="analyzeInput($event)"
      (keydown)="analyzeKeyDown($event)"
    />
  </ng-template>

  <span class="showPassEye {{showPassword ? 'showPassEye_visible' : ''}}" *ngIf="ngType === 'password' && !platform.isEdgeBrowser(87)" (click)='showPassword = !showPassword'></span>
  <ng-content></ng-content>
</div>
