<div id='home-areas-section-header'>{{systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions')}}</div>
<div *ngFor='let areaEx of visibleAreas' [ngClass]="{ 'hai-container': true, 'hai-container-regular': areaEx.area.status !== AreaState.PanicButtons, 'hai-container-narrow': areaEx.area.status === AreaState.PanicButtons }">
    <div class="hai-header" (click)='goToArea(areaEx.area.id)'><span>{{ areaEx.area.name }}</span><span [inlineSVG]="'assets/images/forward.svg'"></span></div>
    <div class="hai-last-status" *ngIf="areaEx.area.status !== AreaState.PanicButtons; else panicButtonArea">
        <div *ngIf="[AreaState.DisarmedReady, AreaState.DisarmedNotReady].includes(areaEx.area.status) && systems.activeSystem.online" [ngClass]="{ 'ale-ready-state': true, 'ale-ready-state-good': areaEx.readyState === ReadyState.Good, 'ale-ready-state-warning': areaEx.readyState !== ReadyState.Good}">
            {{ trans(areaEx.area.status === AreaState.DisarmedReady ? 'systems.statuses.area.readyToArm' : 'systems.statuses.area.notReady') + 
              (areaEx.area.status === AreaState.DisarmedReady ? (getBypassedZoneCount(areaEx.area) > 0 ? (': ' + getBypassedZoneCount(areaEx.area) + ' ' + trans('systems.statuses.area.readyZonesBypassed'))  : '' ) :
              (areaEx.readyState === ReadyState.OpenZones ? (': ' + (getOpenZoneCount(areaEx.area) > 0 ? (getOpenZoneCount(areaEx.area) + ' ') : '') + trans('systems.statuses.area.notReadyZonesOpen')) : '')) }}
        </div>
        <div *ngIf="areaEx.area.status === AreaState.Armed && getBypassedZoneCount(areaEx.area) > 0" class="ale-ready-state ale-ready-state-good">{{ getBypassedZoneCount(areaEx.area) + ' ' + trans('systems.statuses.area.readyZonesBypassed') }}</div>
        <div [ngClass]="{ 'ale-icon': true, 'paint-armed': [AreaState.Armed, AreaState.Stay, AreaState.Sleep].includes(areaEx.area.status), 'paint-disarmed': [AreaState.Disarmed, AreaState.DisarmedReady, AreaState.DisarmedNotReady].includes(areaEx.area.status), 'paint-alarmed': areaEx.area.alarmed }" [inlineSVG]="areaService.getIconByAreaStatus(areaEx.area.status, areaEx.area.alarmed, areaEx.area.alarmType)"></div>
        <div class="ale-text">{{areaService.getDescriptionForStatus(areaEx.area.status, areaEx.area.alarmed)}}</div>
        <div class="ale-user" *ngIf='areaEx.area.lastPerson !== "" && !areaEx.area.alarmed'>{{trans('general.by')}} {{areaEx.area.lastPerson}}</div>
        <div class="ale-time" *ngIf='!areaEx.area.alarmed && areaEx.area.statusTime !== 0'><span *ngIf='!dt.isTodayFromNumber(areaEx.area.statusTime)'>{{dt.getDayTitleFromNumber(areaEx.area.statusTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(areaEx.area.statusTime)}}</div>
    </div>
    <ng-template #panicButtonArea>
      <div class="hai-last-status">
        <ng-container *ngIf="!areaEx.area.alarmed; else panicButtonAreaActivated">
          <div [ngClass]="{ 'ale-icon ale-icon-check': true, 'paint-check': true }" [inlineSVG]="'assets/images/check-simple.svg'"></div>
          <div class="ale-text">{{ trans('events.list.noEvents') }}</div>
        </ng-container>
        <ng-template #panicButtonAreaActivated>
          <div [ngClass]="{ 'ale-icon': true, 'paint-armed': true }" [inlineSVG]="'assets/images/event_alarm.svg'"></div>
          <div class="ale-text">{{ trans('reactionNames.reactions.panic') }}</div>
          <div class="ale-user" *ngIf='areaEx.area.lastPerson !== ""'>{{areaEx.area.lastPerson}}</div>
          <div class="ale-time" *ngIf='areaEx.area.alarmTime !== 0'><span *ngIf='!dt.isTodayFromNumber(areaEx.area.alarmTime)'>{{dt.getDayTitleFromNumber(areaEx.area.alarmTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(areaEx.area.alarmTime)}}</div>
        </ng-template>
    </div>
    </ng-template>
    <div *ngIf="areaEx.area.status !== AreaState.PanicButtons" class="hai-buttons">
        <div *ngIf='areaService.showButtons(areaEx.area.id) && canSleep()' [ngClass]="{ 'hai-button-sleep': true, 'area-action-button': true, 'area-action-arm-active': areaEx.area.status === AreaState.Sleep, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Sleep, areaEx.area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Sleep, areaEx.area)' [inlineSVG]="'assets/images/sleep.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Sleep, areaEx.area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Sleep, false, areaEx.area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(areaEx.area.id)' [ngClass]="{ 'hai-button-arm': true, 'area-action-button': true, 'area-action-arm-active': areaEx.area.status === AreaState.Armed, 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Armed, areaEx.area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Armed, areaEx.area)' [inlineSVG]="'assets/images/arm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Armed, areaEx.area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Armed, false, areaEx.area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(areaEx.area.id)' [ngClass]="{ 'hai-button-disarm': true, 'area-action-button': true, 'area-action-disarm-active': [AreaState.Disarmed, AreaState.DisarmedNotReady, AreaState.DisarmedReady].includes(areaEx.area.status), 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Disarmed, areaEx.area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Disarmed, areaEx.area)' [inlineSVG]="'assets/images/disarm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Disarmed, areaEx.area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Disarmed, false, areaEx.area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(areaEx.area.id) && canStay()' [ngClass]="{ 'hai-button-stay': true, 'area-action-button': true, 'area-action-arm-active': areaEx.area.status === AreaState.Stay, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Stay, areaEx.area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Stay, areaEx.area)' [inlineSVG]="'assets/images/stay.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Stay, areaEx.area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Stay, false, areaEx.area)}}</div>
            </div>
        </div>
        <div *ngIf='!systems.activeSystem.directControl && systems.activeSystemHelper.can.see.outputs() && !areaService.isOutputAssigned(areaEx.area.id) && systems.activeSystemHelper.can.edit.areas()' id='hai-control-info' (click)='goEditArea(areaEx.area.id)'>{{trans('systems.labels.clickToAssignOut')}}</div>
    </div>
    <app-take-action-button *ngIf='areaEx.area.status === AreaState.PanicButtons && areaEx.area.alarmed || shouldShowTakeAction[areaEx.area.id] !== undefined && shouldShowTakeAction[areaEx.area.id]' class='hai_area_alarmed' [areaItem]="areaEx.area"></app-take-action-button>
</div>
